export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

export const LEAF_COMMENTS = 3;
export const COMMENT_LEVELS = 1;
