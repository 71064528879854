import { decomposeColor } from "@mui/system/colorManipulator";

import type { Themes } from "utils/types/theme";

export const setColorVariable = (
  color: string,
  value: string,
  element?: HTMLElement
) => {
  (element ?? document.body).style.setProperty(color, value);
};

export const setThemeClasses = (
  mode: "light" | "dark",
  themes: Themes,
  team: string,
  element?: HTMLElement
) => {
  if (mode === "dark") {
    (element ?? document.documentElement).classList.add("dark");
  } else {
    (element ?? document.documentElement).classList.remove("dark");
  }

  if (themes) {
    const { teams } = themes;
    const theme = teams[team ?? "tpl"][mode];

    if (theme) {
      setColorVariable(
        `--color-button-background`,
        decomposeColor(theme.buttonBackground.hex)?.values.join(" "),
        element
      );

      setColorVariable(
        `--color-primary`,
        decomposeColor(theme.buttonBackground.hex)?.values.join(" "),
        element
      );

      setColorVariable(
        `--color-button-text`,
        decomposeColor(theme.buttonText.hex)?.values.join(" "),
        element
      );
    }
  }
};
