import React, { PropsWithChildren } from "react";

import { SWRConfig } from "swr";

import { ThemeProvider } from "./base";

import type { SiteProps } from "utils/types";

interface Props extends SiteProps {
  root?: HTMLElement;
}

export const ThemeWrapper = ({
  children,
  themes,
  team,
  fallback,
  root,
}: PropsWithChildren<Props>) => (
  <SWRConfig value={fallback ? { fallback } : undefined}>
    <ThemeProvider themes={themes} team={team?.slug} root={root}>
      {children}
    </ThemeProvider>
  </SWRConfig>
);
