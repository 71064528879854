/**
 * `ThemeContext` is a `React Context` used to handle the light/dark theme of the website.
 * @property toggleColorMode - This is a function that will be used to toggle the color mode.
 * @property {"light" | "dark"} mode - This is the current color mode. It can be either light or dark.
 */
import {
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";

import {
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from "@mui/material";

import { useLocalStorage } from "utils/hooks/useLocalStorage";

import { setThemeClasses } from "./tailwind";
import { baseTheme, poppins, siteTheme } from "../mui-theme";

import type { DefaultColors, ThemeColors, Themes } from "utils/types/theme";

type themeContextType = {
  toggleColorMode: () => void;
  mode: "light" | "dark";
  themes: Themes;
};

const themeContextDefaultValues: themeContextType = {
  toggleColorMode: null,
  mode: "light",
  themes: null,
};

export const ThemeContext = createContext<themeContextType>(
  themeContextDefaultValues
);

interface Props extends PropsWithChildren {
  themes: Themes;
  team?: string;
  root?: HTMLElement;
}

type Mode = "light" | "dark";

export const ThemeProvider = ({ children, themes, team, root }: Props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [storedMode, setStoredMode] = useLocalStorage<Mode>("mode");
  const [mode, setMode] = useState<Mode>("light");

  // TODO: fetch themes if null

  /* Setting the mode based on the locally stored value with user preference as a fallback. */
  useEffect(() => {
    setMode(storedMode ?? (prefersDarkMode ? "dark" : "light"));
  }, [prefersDarkMode]);

  /* Creating a theme based on the mode. */
  const theme = useMemo(
    () => (themes ? siteTheme(themes, mode, team) : baseTheme(mode)),
    [mode, themes, team]
  );

  useEffect(() => {
    setThemeClasses(mode, themes, team, root);
  }, [mode, themes, team]);

  /* The dark mode switch would invoke this method. */
  const toggleColorMode = () => {
    setMode((prevMode: string) => (prevMode === "light" ? "dark" : "light"));
    setStoredMode((prevMode: string) =>
      prevMode === "light" ? "dark" : "light"
    );
  };

  return (
    <ThemeContext.Provider value={{ toggleColorMode, mode, themes }}>
      <MuiThemeProvider theme={theme}>
        <main className={`${poppins.className} ${poppins.variable} font-sans`}>
          {children}
        </main>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

/**
 * This hook is used to get the theme context.
 * @returns {themeContextType} - The theme context.
 */
export const useThemeContext = () => {
  return useContext(ThemeContext);
};

/**
 * This hook is used to get the theme of a team.
 * @param {string} team - The team name.
 * @param {"light" | "dark"} forceMode - The mode to force the theme to be.
 * @returns {ThemeColors} - The theme of the team.
 */
export const useTeamTheme = (
  team: string,
  forceMode?: "light" | "dark"
): ThemeColors => {
  const { themes, mode } = useThemeContext();

  const theme = useMemo(
    () =>
      themes?.teams?.[team]?.[forceMode || mode] ??
      themes?.teams.tpl[forceMode || mode],
    [team, mode, forceMode]
  );

  return theme;
};

/**
 * This hook is used to get the theme of a team.
 * @param {"light" | "dark"} forceMode - The mode to force the theme to be.
 * @returns {ThemeColors} - The theme of the team.
 */
export const useBaseTheme = (forceMode?: "light" | "dark"): ThemeColors => {
  const { themes, mode } = useThemeContext();

  const theme = useMemo(
    () =>
      themes?.teams?.tpl?.[forceMode || mode] ??
      themes?.teams.tpl[forceMode || mode],
    [mode, forceMode]
  );

  return theme;
};

/**
 * This hook is used to get the theme of the site.
 * @returns {DefaultColors} - The theme of the site.
 */
export const useSiteTheme = (): DefaultColors => {
  const { themes, mode } = useThemeContext();

  return themes.base[mode];
};
