import type { AdSizeType } from "utils/types/ads";

export const DFP_ACCOUNT_ID = "22966776953";

export const sizeMaps: Record<
  "leaderboard" | "flexBoxSmall" | "flexBoxLarge" | "mobile",
  AdSizeType
> = {
  leaderboard: [
    [728, 90],
    [970, 90],
  ],
  flexBoxSmall: [300, 250],
  flexBoxLarge: [
    [300, 600],
    [300, 250],
  ],
  mobile: [
    [300, 50],
    [320, 50],
    [300, 100],
    [300, 250],
  ],
};

export const cycleGenerator = function* (numMobileAds: number) {
  let i = 0;
  while (true) {
    yield `mflex${(i % numMobileAds) + 1}`;
    i++;
  }
};
