type GoogleTag = typeof googletag;

export const adsToRefresh = [];
export const adFactory = (
  adSlot: googletag.Slot,
  refreshTimer: number,
  googletag: GoogleTag
) => {
  const internalRefreshTimer = refreshTimer;
  const internalGoogletag = googletag;
  let instance = null;
  let viewPercentage = 0;
  let isLoopRun = false;
  let isRefreshable = false;
  let isImpressionViewable = false;

  const setViewPercentage = (vp: number) => {
    viewPercentage = vp;
  };

  const impressionViewable = () => {
    isImpressionViewable = true;
  };

  const loop = () => {
    if (isImpressionViewable && viewPercentage > 50) {
      isLoopRun = true;
      if (isRefreshable) {
        internalGoogletag.pubads().refresh([adSlot]);
        isRefreshable = false;
        isImpressionViewable = false;
      }

      setTimeout(() => {
        if (isImpressionViewable && viewPercentage > 50) {
          internalGoogletag.pubads().refresh([adSlot]);
        } else {
          isRefreshable = true;
        }

        isLoopRun = false;
      }, internalRefreshTimer);
    }
  };

  return {
    runLoop: () => {
      if (instance === null || !isLoopRun) instance = loop;
      return instance;
    },
    setViewPercentage,
    impressionViewable,
    adSlot,
  };
};

export const pushAdSlotToRefresh = (
  adSlot: googletag.Slot,
  refreshTimer: number,
  googletag: GoogleTag
) => {
  const adSlotToInsert = adFactory(adSlot, refreshTimer, googletag);
  adsToRefresh.push(adSlotToInsert);
};

export const refreshViewPercentage = (
  event: googletag.events.SlotVisibilityChangedEvent
) => {
  const { slot, inViewPercentage } = event;
  const adIndex = adsToRefresh.findIndex(
    (el) => el.adSlot.getSlotElementId() === slot.getSlotElementId()
  );
  if (adIndex === -1) return;
  adsToRefresh[adIndex].setViewPercentage(inViewPercentage);
  adsToRefresh[adIndex].runLoop();
};

export const impressionViewable = (
  event: googletag.events.ImpressionViewableEvent
) => {
  const { slot } = event;
  const adIndex = adsToRefresh.findIndex(
    (el) => el.adSlot.getSlotElementId() === slot.getSlotElementId()
  );
  if (adIndex === -1) return;
  adsToRefresh[adIndex].impressionViewable();
  adsToRefresh[adIndex].runLoop();
};

let internalNetworkCode = "";

export const networkCode = {
  get: () => internalNetworkCode,
  set: (nc: string) => (internalNetworkCode = nc),
};
