import {
  type FunctionComponent,
  type PropsWithChildren,
  useEffect,
} from "react";

import { networkCode } from "./utils";

interface Props extends PropsWithChildren {
  networkCode: string;
  target?: [string, string][];
  enableLazyLoad?: boolean;
  enableSingleRequest?: boolean;
  collapseEmptyDivs?: boolean;
  eventImpressionViewable?: (
    event: googletag.events.ImpressionViewableEvent
  ) => void;
  eventSlotOnload?: (event: googletag.events.SlotOnloadEvent) => void;
  eventSlotRenderEnded?: (event: googletag.events.SlotRenderEndedEvent) => void;
  eventSlotRequested?: (event: googletag.events.SlotRequestedEvent) => void;
  eventSlotResponseReceived?: (
    event: googletag.events.SlotResponseReceived
  ) => void;
  eventSlotVisibilityChanged?: (
    event: googletag.events.SlotVisibilityChangedEvent
  ) => void;
}

type GoogleTag = typeof googletag;

const AdsProvider: FunctionComponent<Props> = ({
  networkCode: networkCode$1,
  target = [],
  enableLazyLoad,
  enableSingleRequest,
  collapseEmptyDivs,
  eventImpressionViewable,
  eventSlotOnload,
  eventSlotRenderEnded,
  eventSlotRequested,
  eventSlotResponseReceived,
  eventSlotVisibilityChanged,
  children,
}) => {
  let googletag: GoogleTag;

  const setConfigs = () => {
    if (networkCode$1) networkCode.set(networkCode$1);
    if (enableLazyLoad) googletag?.pubads()?.enableLazyLoad();
    if (collapseEmptyDivs) googletag?.pubads()?.collapseEmptyDivs();
    if (enableSingleRequest) googletag?.pubads()?.enableSingleRequest();
  };

  const setTargeting = () => {
    target.forEach((el) => googletag?.pubads()?.setTargeting(el[0], el[1]));
  };

  const setEvents = () => {
    googletag
      ?.pubads()
      ?.addEventListener(
        "slotOnload",
        (event: googletag.events.SlotOnloadEvent) => {
          if (eventSlotOnload) eventSlotOnload(event);
        }
      );

    googletag
      ?.pubads()
      ?.addEventListener(
        "slotVisibilityChanged",
        (event: googletag.events.SlotVisibilityChangedEvent) => {
          if (eventSlotVisibilityChanged) eventSlotVisibilityChanged(event);
        }
      );

    googletag
      ?.pubads()
      ?.addEventListener(
        "impressionViewable",
        (event: googletag.events.ImpressionViewableEvent) => {
          if (eventImpressionViewable) eventImpressionViewable(event);
        }
      );

    if (eventSlotRenderEnded)
      googletag
        ?.pubads()
        ?.addEventListener("slotRenderEnded", eventSlotRenderEnded);

    if (eventSlotRequested)
      googletag
        ?.pubads()
        ?.addEventListener("slotRequested", eventSlotRequested);

    if (eventSlotResponseReceived)
      googletag
        ?.pubads()
        ?.addEventListener("slotResponseReceived", eventSlotResponseReceived);
  };

  useEffect(() => {
    window.googletag = window.googletag || ({ cmd: [] } as GoogleTag);
    googletag = window.googletag;

    googletag?.cmd?.push(() => {
      setConfigs();
      setEvents();
      setTargeting();
      googletag?.enableServices();
    });
  }, []);

  return <>{children}</>;
};

export default AdsProvider;
