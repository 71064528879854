import React from "react";

const AdScript = () => {
  return React.createElement("script", {
    async: true,
    src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
  });
};

export default AdScript;
