import { useEffect } from "react";

import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dynamic from "next/dynamic";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";

import { DFP_ACCOUNT_ID } from "lib/dfp";
import { ThemeWrapper } from "theme/context/wrapper";
import AdScript from "ui/src/GoogleAds/AdScript";
import AdsProvider from "ui/src/GoogleAds/AdsProvider";

import MetaTags from "@/components/common/MetaTags";

import type { AppProps } from "next/app";

// react-slick styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// font awesome styles
import "fa-icon-parser/styles.css";

// react-phone-input-2 styles
import "react-phone-input-2/lib/style.css";

// app global styles
import "styles/globals.css";

const queryClient = new QueryClient();

const Tracking = dynamic(
  async () => (await import("ui/src/Tracking")).Tracking,
  { ssr: false }
);

const NotificationsContextProvider = dynamic(
  async () =>
    (await import("utils/context/NotificationsAlertContext"))
      .NotificationsContextProvider,
  { ssr: false }
);

const SnackContextProvider = dynamic(
  async () =>
    (await import("utils/context/SnackBarContext")).SnackContextProvider,
  { ssr: false }
);

const AppealContextProvider = dynamic(
  async () =>
    (await import("utils/context/AppealContext")).AppealContextProvider,
  { ssr: false }
);

const TeamsContextProvider = dynamic(
  async () => (await import("utils/context/TeamsContext")).TeamsContextProvider,
  { ssr: false }
);

const UserContextProvider = dynamic(
  async () =>
    (await import("utils/context/UserDetailsContext")).UserContextProvider,
  { ssr: false }
);

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const title =
    pageProps?.metadata?.title ||
    (pageProps?.post
      ? `${pageProps?.post?.title} - TPL`
      : "The Players' Lounge");

  return (
    <>
      <AppCacheProvider {...pageProps}>
        <Head>
          <title key="title">{title}</title>
          <MetaTags metadata={pageProps.metadata} post={pageProps.post} />
          <link rel="preconnect" href={process.env.TPL_USERS_SERVICE} />
          <AdScript />
        </Head>
        {/* Tracking Scripts Start */}
        <Tracking />
        {/* Tracking Scripts End */}
        <QueryClientProvider client={queryClient}>
          <ThemeWrapper team={pageProps.team} {...pageProps}>
            <SessionProvider session={session}>
              <TeamsContextProvider fallback={pageProps.teams}>
                <UserContextProvider>
                  <SnackContextProvider>
                    <NotificationsContextProvider>
                      <AppealContextProvider>
                        <AdsProvider
                          networkCode={DFP_ACCOUNT_ID}
                          enableLazyLoad
                        >
                          <Component {...pageProps} />
                          <ReactQueryDevtools initialIsOpen={false} />
                        </AdsProvider>
                      </AppealContextProvider>
                    </NotificationsContextProvider>
                  </SnackContextProvider>
                </UserContextProvider>
              </TeamsContextProvider>
            </SessionProvider>
          </ThemeWrapper>
        </QueryClientProvider>
      </AppCacheProvider>
    </>
  );
};

export default MyApp;
