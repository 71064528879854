import { FunctionComponent } from "react";

import { useTheme } from "@mui/material";

interface Props {
  metadata?: {
    title?: string;
    description?: string;
  };
}

export const MetaTags: FunctionComponent<Props> = ({ metadata }) => {
  const title = `${
    metadata?.title ? `${metadata.title} - TPL` : "The Players' Lounge"
  }`;

  const description =
    metadata?.description ||
    "A media platform built by athletes offering fans a reimagined experience.";

  return (
    <>
      {/* Favicons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#000000" />

      {/* Metadata */}
      <title key="title">{title}</title>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <meta name="theme-color" content={useTheme().palette.primary.main} />
      <meta name="description" content={description} key="desc" />
      <meta property="og:locale" content="en_US" key="og-locale" />
      <meta property="og:type" content="website" key="type" />
      <meta property="og:title" content={title} key="og-title" />
      <meta name="og:description" content={description} key="og-desc" />
      <meta
        property="og:url"
        content="https://www.theplayerslounge.io"
        key="url"
      />
      <meta
        property="og:site_name"
        content="The Players' Lounge"
        key="og-site-name"
      />
      <meta
        property="og:image"
        content="https://d3ex6dny91jq83.cloudfront.net/meta_Image_b8ff736ab2.webp?updated_at=2023-08-04T18:39:08.773Z"
        key="image"
      />
      <meta property="og:image:width" content="1200" key="image-width" />
      <meta property="og:image:height" content="630" key="image-height" />
      <meta property="og:image:type" content="image/webp" key="image-type" />
      <meta name="twitter:card" content="summary" key="twitter-card" />
      <meta name="twitter:site" content="@ThPlayersLounge" key="twitter-site" />
    </>
  );
};
