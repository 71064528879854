import { CSSProperties } from "react";

import { createTheme } from "@mui/material/styles";
import { Poppins, Merriweather } from "next/font/google";

import { lightenDarkenColor } from "utils/helpers/colorUtils";
import { BREAKPOINTS } from "utils/helpers/constants";
import { Themes } from "utils/types/theme";

/* Extending the typescript definition of the Material UI library. */
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs?: true;
    sm?: true;
    md?: true;
    lg?: true;
    xl?: true;
    xxl?: true;
  }

  interface TypographyVariants {
    footer: CSSProperties;
    description: CSSProperties;
    tag: CSSProperties;
    contentH1: CSSProperties;
    contentH2: CSSProperties;
    contentBody: CSSProperties;
    contentQuote: CSSProperties;
  }

  interface TypographyVariantsOptions {
    footer?: CSSProperties;
    description?: CSSProperties;
    tag?: CSSProperties;
    contentH1?: CSSProperties;
    contentH2?: CSSProperties;
    contentBody?: CSSProperties;
    contentQuote?: CSSProperties;
  }

  interface Palette {
    border: { main: string };
    buttonDefault: {
      main: string;
      contrastText: string;
      hover?: string;
      hoverText?: string;
    };
  }

  interface PaletteOptions {
    border?: { main: string };
    buttonDefault?: {
      main: string;
      contrastText?: string;
      hover?: string;
      hoverText?: string;
    };
  }

  interface TypeBackground {
    card?: string;
  }
}

/* Extending the typescript definition of the Material UI library. */
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    footer: true;
    description: true;
    tag: true;
    contentH1: true;
    contentH2: true;
    contentBody: true;
    contentQuote: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    buttonDefault: true;
  }
}

/* Creating a font object that can be used to create a Material UI theme. */
export const poppins = Poppins({
  weight: ["300", "400", "500", "600", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  variable: "--font-poppins",
  display: "swap",
});

// Merriweather
export const merriweather = Merriweather({
  weight: ["400", "700"],
  style: ["normal"],
  subsets: ["latin"],
  variable: "--font-merriweather",
});

/**
 * Creating a temporary theme object with custom breakpoints
 * that can be used to create the app's Material UI theme.
 */
const breakpointTheme = createTheme({
  breakpoints: {
    values: { ...BREAKPOINTS },
  },
});

const typographyTheme = createTheme({
  ...breakpointTheme,
  typography: {
    fontFamily: poppins.style.fontFamily,
    h1: {
      fontSize: "2.875rem",
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: "0.02em",
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "3.4375rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "4rem",
      },
    },
    h2: {
      fontSize: "2.125rem",
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: "0.02em",
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "2rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "2.5rem",
      },
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: "0.02em",
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "1.8125rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "2.125rem",
      },
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: 1.25,
      fontWeight: 700,
      letterSpacing: "0.02em",
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "1.375rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "1.5rem",
      },
    },
    h6: {
      fontSize: "0.875rem",
      lineHeight: 1.25,
      fontWeight: 700,
      letterSpacing: "0.02em",
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "1.125rem",
      },
    },
    body1: {
      // Primary Text
      fontSize: "1.125rem",
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    body2: {
      // Secondary Text
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    description: {
      fontFamily: poppins.style.fontFamily,
      fontSize: "0.75rem",
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: "0.02em",
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "0.813rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "0.875rem",
      },
    },
    tag: {
      fontFamily: poppins.style.fontFamily,
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: 500,
      letterSpacing: "0.02em",
    },
    caption: {
      // Small text
      fontSize: "0.75rem",
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    footer: {
      // Footer Text
      fontSize: "1.25rem",
      lineHeight: 1.5,
      fontWeight: 300,
      letterSpacing: "0.024rem",
    },
    contentH1: {
      // Article Title Text
      fontSize: "1.5rem",
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: "0.024rem",
    },
    contentH2: {
      // Article H2
      fontSize: "1.375rem",
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: "0.024rem",
    },
    contentBody: {
      // Article Body Text
      fontSize: "0.875rem",
      lineHeight: 1.6,
      fontWeight: 400,
      letterSpacing: "0.02em",
      fontFamily: merriweather.style.fontFamily,
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "0.938rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "1rem",
      },
    },
    contentQuote: {
      // Article Quote Text
      fontSize: "1rem",
      lineHeight: 1.6,
      fontWeight: 400,
      letterSpacing: "0.02em",
      fontFamily: merriweather.style.fontFamily,
      [breakpointTheme.breakpoints.up("md")]: {
        fontSize: "1.063rem",
      },
      [breakpointTheme.breakpoints.up("xl")]: {
        fontSize: "1.125rem",
      },
    },
  },
});

export const defaultTheme = {
  primaryColor: "#EBA900",
  secondaryColor: "#BA0C2F",
  light: {
    pageBackground: "#F2F2F2",
    contentText: "#222222",
    buttonBackgroundHover: "#F2F2F2",
    buttonTextHover: "#000000",
    divider: "#D9D9D6",
    error: "#FF0000",
    verified: "#008000",
    cardBackground: "#FFFFFF",
    buttonText: "#000000",
  },
  dark: {
    pageBackground: "#313131",
    contentText: "#FFFFFF",
    buttonBackgroundHover: "#313131",
    buttonTextHover: "#FFFFFF",
    divider: "#85878A",
    error: "#FF0000",
    verified: "#00FF00",
    cardBackground: "#222222",
    buttonText: "#000000",
  },
};

export const baseTheme = (mode: "light" | "dark") =>
  createTheme({
    ...breakpointTheme,
    ...typographyTheme,
    palette: {
      mode,
      background: {
        default: defaultTheme[mode].pageBackground,
        paper: defaultTheme[mode].pageBackground,
        card: defaultTheme[mode].cardBackground,
      },
      text: {
        primary: defaultTheme[mode].contentText,
      },
      error: {
        main: defaultTheme[mode].error,
      },
      buttonDefault: {
        main: defaultTheme.primaryColor,
        hover: defaultTheme[mode].buttonBackgroundHover,
        contrastText: defaultTheme[mode].buttonText,
        hoverText: defaultTheme[mode].buttonTextHover,
      },
      border: {
        main: defaultTheme[mode].divider,
      },
      primary: {
        main: defaultTheme.primaryColor,
        contrastText: defaultTheme[mode].buttonText,
      },
      secondary: {
        main: defaultTheme.secondaryColor,
      },
      divider: defaultTheme[mode].divider,
      success: {
        main: defaultTheme[mode].verified,
      },
      grey: {
        100: "#E0E0E0",
        200: "#EFEFEF",
        300: "#D9D9D9",
        400: "#9E9E9E",
        500: "#ADADAD",
        600: "#C6C6C6",
        700: "#5B5B5B",
        800: "#313131",
        900: "#363636",
      },
    },
  });

export const siteTheme = (
  themes: Themes,
  mode: "light" | "dark",
  team = "tpl"
) =>
  createTheme({
    ...breakpointTheme,
    ...typographyTheme,
    palette: {
      mode,
      background: {
        default: themes.base[mode].pageBackground.hex,
        paper: themes.base[mode].pageBackground.hex,
        card: themes.base[mode].cardBackground.hex,
      },
      text: {
        primary: themes.base[mode].contentText.hex,
      },
      error: {
        main: themes.base[mode].error.hex,
      },
      buttonDefault: {
        main: themes.teams[team][mode].buttonBackground.hex,
        hover: themes.base[mode].buttonBackgroundHover.hex,
        contrastText: themes.teams[team][mode].buttonText.hex,
        hoverText: themes.base[mode].buttonTextHover.hex,
      },
      border: {
        main: themes.base[mode].divider.hex,
      },
      primary: {
        main: themes.teams[team][mode].buttonBackground.hex,
        contrastText: themes.teams[team][mode].buttonText.hex,
      },
      secondary: {
        main: "#BA0C2F",
      },
      action: {
        active: themes.teams[team][mode].buttonBackground.hex,
        hover: lightenDarkenColor(
          themes.teams[team][mode].buttonBackground.hex,
          40
        ),
        selected: "#DA9100",
        disabledBackground: "#BFBFBF",
        disabled: "#6A6A6A",
      },
      divider: themes.base[mode].divider.hex,
      success: {
        main: themes.base[mode].verified.hex,
      },
      grey: {
        100: "#E0E0E0",
        200: "#EFEFEF",
        300: "#D9D9D9",
        400: "#9E9E9E",
        500: "#ADADAD",
        600: "#C6C6C6",
        700: "#5B5B5B",
        800: "#313131",
        900: "#363636",
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            contentH1: "h1",
            contentH2: "h2",
            contentBody: "p",
            contentQuote: "p",
            footer: "p",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(ownerState.variant !== "text" && {
              transition: "0.3s",
              borderRadius: "6px",
              boxShadow: "none",
              fontSize: "1rem",
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.buttonDefault.main,
              borderColor: themes.teams[team][mode].buttonBorder.hex,
              lineHeight: 1.5,
              letterSpacing: "0.02em",
              padding: 0,
              fontWeight: 600,
              borderWidth: "2px",
              borderStyle: "solid",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: themes.teams[team][mode].buttonBorder.hex,
                color: themes.base[mode].buttonTextHover.hex,
                boxShadow: "none",
              },
              ...(ownerState.size === "large" && {
                fontSize: "1.25rem",
                minWidth: "300px",
                height: "65px",
              }),
              ...(ownerState.size === "medium" && {
                fontSize: "1rem",
                minWidth: "240px",
                height: "55px",
              }),
              ...(ownerState.size === "small" && {
                fontSize: "1rem",
                minWidth: "200px",
                height: "35px",
                borderRadius: "5px",
              }),
              ...(ownerState.variant === "outlined" && {
                borderWidth: "2px",
                borderColor: themes.teams[team][mode].buttonBorder.hex,
                color: themes.base[mode].buttonTextHover.hex,
                backgroundColor: "transparent",
                "&:hover": {
                  borderWidth: "2px",
                  backgroundColor: `${theme.palette.action.hover}1A`,
                },
              }),
              ...(ownerState.variant === "contained" && {
                "&.Mui-disabled": {
                  border: "none",
                  ...(theme.palette.mode === "dark" && {
                    color: "white",
                  }),
                },
              }),
            }),
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => ({
            textDecoration: "none",
            color: theme.palette.action.active,
            ":hover": {
              color: theme.palette.action.hover,
            },
            ":focus": {
              color: theme.palette.action.hover,
            },
          }),
        },
      },
      MuiCollapse: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...(theme.palette.mode === "light" && {
              backgroundColor: "#E0E0E0",
            }),
            ...(theme.palette.mode === "dark" && {
              backgroundColor: "#363636",
            }),
          }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: "#000000",
            borderRadius: "2px",
            ...(theme.palette.mode === "light" && {
              backgroundColor: "#FFFFFF",
              color: "#313131",
            }),
            ...(theme.palette.mode === "dark" && {
              backgroundColor: "#FFFFFF33",
              color: "#FFFFFF",
            }),
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "2px",
          },
          inputSizeSmall: {
            padding: "12px",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            borderRadius: "2px",
            backgroundColor: "#FAE5E8",
            fontStyle: "italic",
            fontSize: "0.875rem",
            lineHeight: 1.2,
            margin: "0.5rem 0",
            padding: "0.5rem",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: "1440px",
          },
          [breakpointTheme.breakpoints.up("lg")]: {
            maxWidth: "1440px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.08)",
            ...(theme.palette.mode === "light" && {
              backgroundColor: theme.palette.background.paper,
            }),
            ...(theme.palette.mode === "dark" && {
              backgroundColor: theme.palette.background.card,
            }),
            ...(!ownerState.raised && {
              [breakpointTheme.breakpoints.up("md")]: {
                boxShadow: "none",
                background: theme.palette.background.paper,
              },
            }),
          }),
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: ({ theme }) => ({
            button: {
              color: theme.palette.grey[500],
              borderColor: theme.palette.grey[500],
              borderRadius: "0.75rem",
            },

            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: theme.palette.primary.main,
            },

            "& .Mui-selected": {
              color: theme.palette.buttonDefault.contrastText ?? "white",
              border: "none",
            },
            "& .MuiPagination-ul li:last-child": {
              fontSize: "0.875rem",
              "& button": {
                "& svg": {
                  display: "none",
                },
                border: "none",
                "&:hover": {
                  color: theme.palette.text.primary,
                  background: "none",
                },
              },
              "&:hover": {
                background: "none",
              },
            },
            "& .MuiPagination-ul li:last-child button::before": {
              content: "'Next'",
              "& svg": {
                visibility: "hidden",
                display: "none",
              },
              "& hover": {
                background: "transparent !important",
              },
            },
            "& .MuiPagination-ul li:first-of-type": {
              fontSize: "0.875rem",
              "& button": {
                "& svg": { display: "none" },
                border: "none",
                color: theme.palette.text.primary,
                "&:hover": {
                  color: theme.palette.text.primary,
                  background: "none",
                },
              },
            },
            "& .MuiPagination-ul li:first-of-type button::after": {
              content: "'Prev'",
              "& svg": {
                visibility: "hidden",
                display: "none",
              },
            },
          }),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&.MuiTableRow-hover:hover": {
              color: "#212121",
              backgroundColor: theme.palette.background.card,
              borderRadius: "10px !important",
              boxShadow: "0px 3px 30px 0px rgba(0, 0, 0, 0.07)",
              "& td:first-of-type": {
                borderRadius: "10px 0 0 10px",
              },
              "& td:last-of-type": {
                borderRadius: "0 10px 10px 0",
              },
              "& td": {
                color: theme.palette.text.primary,
              },
            },
            "&.Mui-selected": {
              color: "#FFFFFF",
              borderRadius: "10px !important",
              boxShadow: "0px 3px 30px 0px rgba(0, 0, 0, 0.07)",
              "& td": {
                color: "#FFFFFF",
              },
              "& td:first-of-type": {
                borderRadius: "10px 0 0 10px",
              },
              "& td:last-of-type": {
                borderRadius: "0 10px 10px 0",
              },
            },
          }),
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            width: 56,
            height: 30,
            ...(ownerState.size === "small" && {
              width: 41,
              height: 23,
            }),
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: 2,
              transitionDuration: "300ms",
              "&.Mui-checked": {
                transform: "translateX(25px)",
                ...(ownerState.size === "small" && {
                  transform: "translateX(17px)",
                }),
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#EBA900",
                  opacity: 1,
                  border: 0,
                },
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 26,
              height: 26,
              ...(ownerState.size === "small" && {
                width: 19,
                height: 19,
              }),
            },
            "& .MuiSwitch-track": {
              borderRadius: 22,
              backgroundColor:
                theme.palette.mode === "dark" ? "#E9E9EA" : "#39393D",
              opacity: 1,
              transition: theme.transitions.create(["background-color"], {
                duration: 500,
              }),
            },
          }),
        },
      },
    },
  });
