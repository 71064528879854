import React, { FunctionComponent } from "react";

import { MetaTags as AppMetaTags } from "ui/src/MetaTags";

import type { ImagePost, VideoPost } from "utils/types/content";

interface Props {
  post?: ImagePost | VideoPost;
  metadata?: {
    title?: string;
    description?: string;
  };
}

const MetaTags: FunctionComponent<Props> = ({ post, metadata }) => {
  if (!post) {
    return <AppMetaTags metadata={metadata} />;
  }

  const metaSocial = post?.seo?.metaSocial;
  const facebookData = metaSocial
    ? metaSocial.find((obj) => obj.socialNetwork === "Facebook")
    : null;

  const twitterData = metaSocial
    ? metaSocial.find((obj) => obj.socialNetwork === "Twitter")
    : null;

  const YouTubeVideo =
    post.typename === "VideoPost" ? JSON.parse(post.youTube) : null;

  const thumbnail = YouTubeVideo?.thumbnail;
  const imageType = thumbnail?.split(".")[thumbnail.split(".").length - 1];

  const title = `${metadata?.title || post.title} - The Player's Lounge`;

  return (
    <>
      {/* Favicons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#000000" />

      {/* Metadata */}
      <title key="title">{title}</title>
      <meta
        property="article:published_time"
        content={post.publishedAt}
        key="article-published"
      />
      <meta
        property="article:modified_time"
        content={post.updatedAt}
        key="article-modified"
      />
      <meta property="og:type" content="article" key="type" />
      <meta property="og:url" content={post.url} key="url" />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter-card"
      />
      <meta name="twitter:site" content="@ThPlayersLounge" key="twitter-site" />
      <link rel="canonical" href={post.url} />
      <link href={post.url} />
      <meta
        property="article:author"
        content="author field from Strapi"
        key="author"
      />
      <meta property="og:image:height" content="630" key="image-height" />
      <meta property="og:image:width" content="1200" key="image-width" />
      {post.seo?.metaDescription ? (
        <meta
          name="description"
          content={post.seo.metaDescription}
          key="desc"
        />
      ) : null}
      {facebookData ? (
        <>
          <meta
            property="og:title"
            content={facebookData.title}
            key="og-title"
          />
          <meta
            property="og:description"
            content={facebookData.description}
            key="og-desc"
          />
          {!!facebookData.image?.url && (
            <>
              <meta
                property="og:image"
                content={facebookData.image.url}
                key="image"
              />
              <meta
                property="og:image:type"
                content={`image/${facebookData.image.ext?.replace(".", "")}`}
                key="image-type"
              />
            </>
          )}
        </>
      ) : (
        <>
          <meta property="og:title" content={post.title} key="og-title" />

          <meta property="og:image" content={post.cover?.url} key="image" />
          <meta
            property="og:image:type"
            content={`image/${post.cover?.ext.replace(".", "")}`}
            key="image-type"
          />
        </>
      )}
      {twitterData ? (
        <>
          <meta
            property="twitter:title"
            content={twitterData.title}
            key="twitter-title"
          />
          <meta
            property="twitter:description"
            content={twitterData.description}
            key="twitter-desc"
          />
          {!!twitterData.image?.url && (
            <meta
              property="twitter:image"
              content={twitterData.image.url}
              key="twitter-image"
            />
          )}
        </>
      ) : null}
      {YouTubeVideo ? (
        <>
          <meta property="og:image" content={thumbnail} key="image" />
          <meta
            property="og:image:type"
            content={`image/${imageType}`}
            key="image-type"
          />
        </>
      ) : null}
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
    </>
  );
};

export default MetaTags;
