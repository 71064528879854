/**
 * @module ColorUtils
 * @description Color utility functions
 */

/**
 * Lighten or darken a color
 * @param {string} col The color to lighten or darken
 * @param {number} amt The amount to lighten or darken the color
 * @returns {string} The new color
 * @example
 * const newColor = lightenDarkenColor("#FF0000", 20);
 * console.log(newColor); // "#FF3333"
 * @example
 * const newColor = lightenDarkenColor("#FF0000", -20);
 * console.log(newColor); // "#CC0000"
 */
export const lightenDarkenColor = (col: string, amt: number): string => {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

/**
 * Convert a hex color to an RGB color
 * @param {string} hex The hex color to convert
 * @returns {{ r: number, g: number, b: number }} The RGB color
 * @example
 * const rgbColor = hexToRgb("#FF0000");
 * console.log(rgbColor); // { r: 255, g: 0, b: 0 }
 */
export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;

const GAMMA = 2.4;

const luminance = ([r, g, b]: number[]) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
};

const contrast = (rgb1: number[], rgb2: number[]) => {
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

/**
 * Get the contrast between two colors
 * @param {string} hexColor1 The first color
 * @param {string} hexColor2 The second color
 * @returns {number} The contrast between the two colors
 * @example
 * const contrast = getColorContrast("#FF0000", "#00FF00");
 * console.log(contrast); // 2.15
 */
export const getColorContrast = (hexColor1: string, hexColor2: string) => {
  const rgbColor1 = hexToRgb(hexColor1);
  const rgbColor2 = hexToRgb(hexColor2);

  return contrast(
    [rgbColor1.r, rgbColor1.g, rgbColor1.b],
    [rgbColor2.r, rgbColor2.g, rgbColor2.b]
  );
};
